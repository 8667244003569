<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="document-editor">
    <div class="document-editor__toolbar"></div>
    <div class="document-editor__editable-container">
      <ckeditor
        :model-value="modelValue"
        :editor="editor"
        :config="config"
        @ready="onReady"
        @update:model-value="$emit('update:model-value', $event)"
      ></ckeditor>
    </div>
  </div>
</template>
<script>
import DecoupledDocumentEditor from '@careerpartner/document-builder-editor';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { isNil } from 'lodash';

export default {
  name: 'DocumentEditor',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      editor: DecoupledDocumentEditor,
      config: {
        insertImage: {
          configuredUrl: PARAMETER_STORE.IMAGE_SCOPE,
        },
      },
      editorInstance: null,
    };
  },
  watch: {
    disabled: {
      immediate: true,
      deep: true,
      handler(isDisabled) {
        const readOnlyFeatureId = 'defaultLock';
        const intervalId = setInterval(() => {
          if (!isNil(this.editorInstance)) {
            isDisabled
              ? this.editorInstance.enableReadOnlyMode(readOnlyFeatureId)
              : this.editorInstance.disableReadOnlyMode(readOnlyFeatureId);
            clearInterval(intervalId);
          }
        }, 200);
      },
    },
  },
  methods: {
    onReady(editor) {
      this.editorInstance = editor;

      const toolbarContainer = document.querySelector(
        '.document-editor__toolbar'
      );

      const toolbarElement = editor.ui.view.toolbar.element;
      toolbarElement.classList.add('ck-reset_all');

      toolbarContainer.appendChild(toolbarElement);
      // scoping css because ck-body-wrapper is append to body

      editor.ui.view.body._bodyCollectionContainer.parentNode.classList.add(
        'epos-document-builder-scope'
      );
    },

    appendText(text) {
      this.editorInstance.model.change((writer) => {
        const insertPosition =
          this.editorInstance.model.document.selection.getFirstPosition();

        writer.insertText(text, insertPosition);
      });
    },
  },
};
</script>
