const LANGUAGE_BASED_IDENTIFIERS = ['lang', 'locale'];

export const updateBindingLocale = (template, languageIdentifier) => {
  if (!template || !languageIdentifier) {
    return template;
  }

  const regexString = `((${LANGUAGE_BASED_IDENTIFIERS.join(
    '|'
  )})=["'])([a-z]{2})(["'])`;

  const regex = new RegExp(regexString, 'g');

  return template.replace(regex, `$1${languageIdentifier}$4`);
};
