import { store } from '@/store';
import { router } from '@/router';

export const successHandler = (response) => response;
export const errorHandler = async (error) => {
  // in case response is a blob - transform data to readable object
  if (
    error?.request?.responseType === 'blob' &&
    error?.response?.data instanceof Blob &&
    error?.response?.data?.type &&
    error?.response?.data?.type?.toLowerCase().indexOf('json') != -1
  ) {
    error.response.data = JSON.parse(await error.response.data.text());
  }

  let path = '';
  switch (error?.response?.status) {
    case 404:
      path = 'NotFound404';
      break;
    case 403:
      path = 'Forbidden403';
      break;
    case 500:
      path = 'GeneralErrorPage';
      break;
  }
  if (['NotFound404', 'Forbidden403'].includes(path)) {
    await router.push({ name: path });
    return;
  } else if (path === 'GeneralErrorPage') {
    await router.push({ name: path });
  }

  await store.dispatch('errors/pushError', error, { root: true });
  return Promise.reject(error);
};
