<template>
  <div id="modal-container">
    <n-modal
      :is-open="isOpen"
      allow-overflow
      title="Personalisierte Vorschau"
      @close="handleClose"
    >
      <form class="grid gap-y-4 overflow-y-auto" @submit.prevent>
        <div
          v-for="(binding, bindingIndex) in bindings"
          :key="bindingIndex"
          class="grid gap-y-2"
        >
          <p class="font-bold">{{ binding.name }}</p>
          <p>{{ binding.description }}</p>

          <template v-for="(param, paramIndex) in binding.params">
            <n-input
              v-if="param.type === 'select'"
              :id="param.name"
              :key="`${paramIndex}-if`"
              v-model="formData.bindingInput[binding.name][param.name]"
              type="select"
              :label="param.name"
              :options="param.values"
              option-label="label"
              track-by="value"
              mapped
              @update:model-value="
                changeField(binding.name, param.name, $event)
              "
            />
            <n-input
              v-else
              :id="param.name"
              :key="`${paramIndex}-else`"
              v-model="formData.bindingInput[binding.name][param.name]"
              :label="param.name"
              @update:model-value="
                changeField(binding.name, param.name, $event)
              "
            />
          </template>
        </div>

        <n-checkbox
          id="preview-modal-enable-precondition"
          v-model="formData.enablePrecondition"
          check-value="formData.enablePrecondition"
          label="Vorbedingung überprüfen"
        />

        <div class="flex flex-row">
          <n-button :disabled="isLoading" class="ml-auto" @click="submitForm">
            <n-icon
              v-if="isLoading"
              class="animate-spin mr-2"
              icon="mdi-loading"
              size="sm"
            />
            Ausführen
          </n-button>
        </div>
      </form>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'PreviewModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    bindings: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'submit'],

  data: () => ({
    formData: {
      bindingInput: {},
      enablePrecondition: false,
    },
  }),

  watch: {
    isOpen(isOpenNew) {
      if (!isOpenNew) {
        this.resetForm();
      }
    },

    bindings() {
      this.setDefaultFormDataFromBindings();
    },
  },

  created() {
    this.setDefaultFormDataFromBindings();
  },
  methods: {
    setDefaultFormDataFromBindings() {
      this.bindings.forEach((binding) => {
        this.formData.bindingInput[binding.name] = {};
        binding.params.forEach((param) => {
          this.formData.bindingInput[binding.name][param.name] = param.example;
        });
      });
    },

    handleClose() {
      this.$emit('close');
    },

    submitForm() {
      this.$emit('submit', this.formData);
    },

    resetForm() {
      this.setDefaultFormDataFromBindings();
      this.formData.enablePrecondition = false;
    },

    changeField(bindingName, paramName, value) {
      this.formData.bindingInput[bindingName][paramName] = value;
    },
  },
};
</script>
