export const LANGUAGE_OPTIONS = Object.freeze([
  {
    label: 'Deutsch',
    value: 'de',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Español',
    value: 'es',
  },
]);
