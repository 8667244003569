import { ReportBuilderApi } from '../../../../services/report-builder-api';

export default {
  // CRUD
  async createNewDocumentTemplate({ commit, getters }) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const { data: createdDocumentTemplate } =
        await ReportBuilderApi.createNewDocumentTemplate(
          getters.documentTemplateCreatePayload
        );

      commit('setDocumentTemplate', createdDocumentTemplate);
    } finally {
      commit('setLoading', false);
    }
  },

  async loadDocumentTemplate({ commit }, id) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);
    try {
      const { data: loadedDocumentTemplate } =
        await ReportBuilderApi.getDocumentTemplateById(id);
      commit('setDocumentTemplate', loadedDocumentTemplate);
    } finally {
      commit('setLoading', false);
    }
  },

  async updateDocumentTemplate({ commit, getters }, id) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const { data: updatedDocumentTemplate } =
        await ReportBuilderApi.patchDocumentTemplateById(
          id,
          getters.documentTemplateUpdatePayload
        );

      commit('setDocumentTemplate', updatedDocumentTemplate);
    } finally {
      commit('setLoading', false);
    }
  },
};
