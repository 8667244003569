<template>
  <div v-if="!loadingLastChangedBy">
    <section
      class="flex flex-col lg:grid lg:grid-cols-12 gap-x-6 gap-y-7 mt-16"
    >
      <div class="col-span-6 col-end-7">
        <h3 class="text-blue-600 uppercase font-bold">
          Allgemeine Informationen zum Dokument
        </h3>
      </div>
      <div class="col-span-6 col-end-7">
        <n-input
          id="document-template-name"
          v-model="documentTemplateName"
          label="Titel des Dokuments"
          required
        />
      </div>
      <div class="col-span-6 col-end-7">
        <div class="flex">
          <label class="text text-black flex-grow">
            Fallback Care Template verwenden
          </label>
          <n-toggle
            id="carePrintEnabled"
            v-model="carePrintEnabled"
            :label="carePrintEnabled ? 'Aktiv' : 'Deaktiviert'"
            icon-enabled="mdi-lock-open-variant"
            icon-disabled="mdi-lock"
            :disabled="isPublished"
          />
        </div>
      </div>
      <div class="col-span-6 col-end-7">
        <n-select
          id="document-template-care-fallback"
          v-model="selectedCareDocumentId"
          label="Zugehöriges Care Dokument auswählen"
          option-label="label"
          track-by="id"
          searchable
          :options="allCareDocuments"
          :disabled="!carePrintEnabled || isPublished"
        />
      </div>

      <div v-if="!isNewDocument" class="col-span-6 col-end-7">
        <div class="flex">
          <label class="text text-black flex-grow">Status</label>
          <n-toggle
            id="active"
            v-model="documentTemplateActive"
            :label="documentTemplateActive ? 'Aktiv' : 'Deaktiviert'"
            icon-enabled="mdi-lock-open-variant"
            icon-disabled="mdi-lock"
          />
        </div>
      </div>
      <div class="col-span-6 col-end-7">
        <last-changed-by-i-a-m-user-id-section
          v-if="latestDocument"
          :updated-at="latestDocument?.updatedAt"
          :last-name="userInfo.lastName"
          :first-name="userInfo.firstName"
          :uuid="latestDocument.changedByIAMUserId"
        />
      </div>
    </section>
    <section
      class="flex flex-col lg:grid lg:grid-cols-12 gap-x-6 gap-y-7 mt-16"
    >
      <div class="col-span-6 col-end-7">
        <h3 class="text-blue-600 uppercase font-bold">
          Detailangaben zur Version
        </h3>
      </div>

      <div class="col-span-6 col-end-7">
        <document-template-version-file-name
          v-model="documentTemplateVersionFilename"
          :disabled="isPublished"
        />
      </div>
      <div class="col-span-6 col-end-7">
        <document-template-version-description
          v-model="documentTemplateVersionDescription"
          :disabled="isPublished"
        />
        <letter-counter
          :max-letters="160"
          :model-value="documentTemplateVersionDescription"
        />
      </div>
      <div class="col-span-6 align-self-start text-xs">
        <h4 class="uppercase text-blue-600 font-bold leading-5">
          Anforderungen an eine Beschreibung
        </h4>
        <ul class="text-gray-700">
          <li class="flex items-center">
            <n-icon class="pr-1" icon="mdi-check" />
            <span>Zu welchem Zweck soll das Dokument genutzt werden?</span>
          </li>
          <li class="flex items-center">
            <n-icon class="pr-1" icon="mdi-check" />
            <span>Welche Units nutzen das Dokument?</span>
          </li>
        </ul>
      </div>
      <div class="col-span-6 col-end-7">
        <document-template-version-tags
          v-model="documentTemplateVersionTags"
          :options="allTags"
          :disabled="isPublished"
        />
      </div>
      <div class="col-span-6 col-end-7">
        <document-template-version-precondition
          v-model="documentTemplateVersionPrecondition"
          :disabled="isPublished"
        />
      </div>
      <div class="col-span-6 col-end-7">
        <document-template-version-precondition-feedback
          v-model="documentTemplateVersionPreconditionFeedback"
          :disabled="isPublished"
        />
      </div>
      <div class="col-span-6 col-end-7">
        <document-template-language-selector
          v-model="languageIdentifier"
          :disabled="isPublished"
        />
      </div>
      <div class="col-span-6 col-end-7">
        <n-select
          id="document-template-footer-version"
          v-model="documentTemplateVersionFooterVersionName"
          label="Auswahl der Fußzeile"
          option-label="description"
          track-by="name"
          searchable
          :options="allFooterVersions"
          :disabled="isPublished"
        />
      </div>
      <div class="col-span-full">
        <span class="text-caption text-gray-700">* erforderlich</span>
      </div>
    </section>
  </div>
  <div v-else>
    <div class="flex flex-col items-center mt-12">
      <n-simple-loader />
      <p class="text-cta">Einstellung wird geladen...</p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import LetterCounter from '@/components/editor/LetterCounter.vue';
import DocumentTemplateVersionDescription from '@/components/document-builder/document-template-version/DocumentTemplateVersionDescription';
import DocumentTemplateVersionTags from '@/components/document-builder/document-template-version/DocumentTemplateVersionTags';
import DocumentTemplateVersionPrecondition from '@/components/document-builder/document-template-version/DocumentTemplateVersionPrecondition';
import DocumentTemplateVersionPreconditionFeedback from '@/components/document-builder/document-template-version/DocumentTemplateVersionPreconditionFeedback';
import DocumentTemplateVersionFileName from '@/components/document-builder/document-template-version/DocumentTemplateVersionFileName';
import LastChangedByIAMUserIdSection from '@/components/document-builder/LastChangedByIAMUserIdSection.vue';
import DocumentTemplateLanguageSelector from '@/components/document-builder/document-template-version/DocumentTemplateLanguageSelector.vue';

export default {
  name: 'DocumentSettingsForm',
  components: {
    DocumentTemplateLanguageSelector,
    LastChangedByIAMUserIdSection,
    LetterCounter,
    DocumentTemplateVersionDescription,
    DocumentTemplateVersionPrecondition,
    DocumentTemplateVersionPreconditionFeedback,
    DocumentTemplateVersionFileName,
    DocumentTemplateVersionTags,
  },
  data() {
    return {
      latestDocument: null,
      loadingLastChangedBy: false,
    };
  },
  computed: {
    ...mapGetters('documentBuilder', ['isNewDocument', 'isPublished']),
    ...mapState('documentBuilder', [
      'allTags',
      'allCareDocuments',
      'allFooterVersions',
      'allDocumentTemplateVersions',
      'loadedDocumentTemplate',
    ]),
    documentsAndDocumentsVersions() {
      return [...this.allDocumentTemplateVersions, this.loadedDocumentTemplate];
    },
    ...mapState('app', ['userInfo']),
    ...mapFields('documentBuilder', {
      documentTemplateName: 'editedDocumentTemplate.name',
      documentTemplateActive: 'editedDocumentTemplate.active',
      carePrintEnabled: 'editedDocumentTemplate.carePrintEnabled',
      languageIdentifier: 'editedDocumentTemplateVersion.languageIdentifier',
      selectedCareDocumentId: 'editedDocumentTemplate.careDocumentId',
      documentTemplateVersionDescription:
        'editedDocumentTemplateVersion.description',
      documentTemplateVersionFilename: 'editedDocumentTemplateVersion.fileName',
      documentTemplateVersionPrecondition:
        'editedDocumentTemplateVersion.precondition',
      documentTemplateVersionPreconditionFeedback:
        'editedDocumentTemplateVersion.preconditionFeedback',
      documentTemplateVersionTags: 'editedDocumentTemplateVersion.tags',
      documentTemplateVersionFooterVersionName:
        'editedDocumentTemplateVersion.footerVersionName',
    }),
  },
  watch: {
    documentsAndDocumentsVersions: {
      immediate: true,
      async handler(documents) {
        this.loadingLastChangedBy = true;
        await this.resetUserInfo();
        const currentDocument = this.getMostRecentDocument(documents);

        if (!currentDocument || !currentDocument.changedByIAMUserId) {
          this.latestDocument = currentDocument;
          this.loadingLastChangedBy = false;
          return;
        }

        await this.getUserInfo(currentDocument.changedByIAMUserId);

        this.latestDocument = currentDocument;
        this.loadingLastChangedBy = false;
      },
    },
  },
  methods: {
    ...mapActions('app', ['resetUserInfo', 'getUserInfo']),
    getMostRecentDocument(allDocuments) {
      const documents = allDocuments.filter((item) => 'updatedAt' in item);
      if (documents.length === 0) {
        return null;
      }

      return documents.reduce((prev, current) => {
        return prev.updatedAt > current.updatedAt ? prev : current;
      });
    },
  },
};
</script>
