import { createApiClient, authMiddleware } from '@epos/core-applets';

const ApiClient = createApiClient({ basePath: '/iam/v2/' });

const pluckData = (resp) => resp.data;

const getUserById = async (userId) => {
  const token = await authMiddleware.getToken();
  return ApiClient.instance
    .get(`users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(pluckData);
};

export const IamApi = {
  getUserById,
};
