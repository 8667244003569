import { ReportBuilderApi } from '../../../../services/report-builder-api';

export default {
  // CRUD
  async createNewDocumentTemplateVersion(
    { commit, dispatch, getters },
    documentTemplateId
  ) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      // eslint-disable-next-line no-unused-vars
      const {
        documentTemplateVersionCreatePayload,
        tagDiff: { created, attached },
      } = getters;

      const { data: createdDocumentTemplateVersion } =
        await ReportBuilderApi.createNewDocumentTemplateVersion(
          documentTemplateId,
          documentTemplateVersionCreatePayload
        );
      const createdTags = await ReportBuilderApi.createTags(created);

      // then attach created and existing tags
      ReportBuilderApi.attachTagsToVersion(
        [...createdTags, ...attached],
        createdDocumentTemplateVersion.id
      );

      commit('setDocumentTemplateVersion', {
        ...createdDocumentTemplateVersion,
        tags: [...createdTags, ...attached],
      });

      dispatch(
        'snackbar/updateSnackbar',
        { message: 'Entwurf erstellt' },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async createNewDocumentTemplateVersionDraft(
    { commit, dispatch, getters },
    documentTemplateId
  ) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      // eslint-disable-next-line no-unused-vars
      const {
        documentTemplateVersionCreatePayload,
        tagDiff: { new: allNewTags },
      } = getters;

      const { data: createdDocumentTemplateVersion } =
        await ReportBuilderApi.createNewDocumentTemplateVersion(
          documentTemplateId,
          documentTemplateVersionCreatePayload
        );

      // then attach created and existing tags
      ReportBuilderApi.attachTagsToVersion(
        allNewTags,
        createdDocumentTemplateVersion.id
      );

      commit('setDocumentTemplateVersion', {
        ...createdDocumentTemplateVersion,
        tags: allNewTags,
      });

      dispatch(
        'snackbar/updateSnackbar',
        { message: 'Entwurf erstellt' },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async loadAllDocumentTemplateVersions({ commit }, id) {
    const { data: documentTemplateVersions } =
      await ReportBuilderApi.getAllDocumentTemplateVersions(id);
    commit('setDocumentTemplateVersions', documentTemplateVersions);
  },

  async loadDocumentTemplateVersion({ commit }, id) {
    const { data: documentTemplateVersion } =
      await ReportBuilderApi.getDocumentTemplateVersionById(id);
    commit('setDocumentTemplateVersion', documentTemplateVersion);
  },

  async updateDocumentTemplateVersion(
    { commit, getters, dispatch },
    documentTemplateVersionId
  ) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      // eslint-disable-next-line no-unused-vars
      const {
        documentTemplateVersionUpdatePayload,
        tagDiff: { created, attached, detached, untouched },
      } = getters;

      const { data: updatedDocumentTemplateVersion } =
        await ReportBuilderApi.patchDocumentTemplateVersionById(
          documentTemplateVersionId,
          documentTemplateVersionUpdatePayload
        );

      // first create all new tags
      const createdTags = await ReportBuilderApi.createTags(created);

      // then attach created and existing tags
      await ReportBuilderApi.attachTagsToVersion(
        [...createdTags, ...attached],
        documentTemplateVersionId
      );

      // then detach deleted tags
      await ReportBuilderApi.detachTagsFromVersion(
        detached,
        documentTemplateVersionId
      );

      commit('setDocumentTemplateVersion', {
        ...updatedDocumentTemplateVersion,
        tags: [...createdTags, ...attached, ...untouched],
      });

      dispatch(
        'snackbar/updateSnackbar',
        { message: 'Entwurf gespeichert' },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },

  // more actions
  async publishDocumentTemplateVersion({ commit, dispatch, getters }, id) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      const {
        tagDiff: { new: allNewTags },
      } = getters;

      const { data: publishedDocumentTemplateVersion } =
        await ReportBuilderApi.publishDocumentTemplateVersion(id);

      commit('setDocumentTemplateVersion', {
        ...publishedDocumentTemplateVersion,
        tags: allNewTags,
      });

      dispatch(
        'loadAllDocumentTemplateVersions',
        publishedDocumentTemplateVersion.documentTemplateId
      );

      dispatch(
        'snackbar/updateSnackbar',
        { message: 'Dokument veröffentlicht' },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
};
