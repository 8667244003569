const BREADCRUMBS = {
  search: {
    label: 'Dokumentenverwaltung',
    to: { name: 'search' },
  },
  documentCreate: {
    label: 'Neues Dokument',
    to: { name: 'new-document' },
  },
  documentDetails: {
    label: 'Dokument bearbeiten',
    to: { name: 'edit-document' },
  },
};

export { BREADCRUMBS };
