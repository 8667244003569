<template>
  <div class="search-result grid grid-cols-12 items-center py-1 gap-x-4 px-4">
    <div class="col-span-4">
      <p class="font-bold mb-0">
        {{ result.documentTemplate.name }}
      </p>
    </div>
    <div class="col-span-2">
      <p class="search-result__label">Version</p>
      <document-version-chip :version="result.version" text-classes="text-sm" />
    </div>
    <div class="col-span-2">
      <p class="search-result__label">Status</p>
      <document-state-chip
        :published="result.publishedAt !== null"
        text-classes="text-sm"
      />
    </div>
    <div class="col-span-4">
      <div class="flex flex-row flex justify-end flex-wrap gap-2">
        <n-chip
          v-for="tag in result.tags"
          :key="tag.id"
          class="bg-gray-500 px-3"
          text-classes="text-sm text-black font-bold"
          default-style
        >
          {{ tag.name }}
        </n-chip>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentStateChip from '@/components/document-builder/DocumentStateChip';
import DocumentVersionChip from '@/components/document-builder/DocumentVersionChip';

export default {
  name: 'SearchResult',
  components: {
    DocumentStateChip,
    DocumentVersionChip,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
.search-result__label {
  @apply text-xs mb-0 capitalize text-gray-700;
}
</style>
