<template>
  <n-banner
    v-if="hasErrors"
    variant="error"
    class="fixed z-[1001] left-0 right-0 top-0"
    :actions="actions"
    @click:reload="reloadPage"
    @close="dismissError"
  >
    <template #message>
      {{ lastErrorFormatted }}
    </template>
  </n-banner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ErrorBanner',
  data: function () {
    return {
      actions: [{ name: 'reload', text: 'Reload' }],
    };
  },
  computed: {
    ...mapGetters('errors', ['hasErrors', 'lastErrorFormatted']),
  },
  methods: {
    ...mapActions('errors', ['dismissError']),
    reloadPage() {
      location.reload();
    },
  },
};
</script>
