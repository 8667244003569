export const BINDING_TEXTS = {
  headerCaption: 'Dynamische Daten',
  queryBindingCaption: 'Datenquellen',
  variableBindingCaption: 'Benutzereingaben',
  examplePrefix: 'Beispiel:',
  descriptionPrefix: 'Beschreibung:',
  noInfo: 'Keine Informationen',
  noData: 'Keine Daten',
  searchLabel: 'Suche',
};

export const PROPERTY_TYPE_ICON_TEXTS = {
  string: 'Text',
  number: 'Zahl',
  multiLine: 'Mehrzeiliger Text',
  date: 'Datum',
};
