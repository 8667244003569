import { Base64 } from 'js-base64';
import { isEqual, omit, without } from 'lodash';
import { unscopeImages } from '@/utils/scopedImageTransformer';
import { updateBindingLocale } from '@/utils/updateBindingLocale';

export default {
  isPublished({ loadedDocumentTemplateVersion: documentTemplateVersion }) {
    return documentTemplateVersion.publishedAt !== null;
  },

  isDocumentTemplateVersionModified({
    loadedDocumentTemplateVersion,
    editedDocumentTemplateVersion,
  }) {
    return !isEqual(
      loadedDocumentTemplateVersion,
      editedDocumentTemplateVersion
    );
  },

  documentTemplateVersionCreatePayload({
    editedDocumentTemplateVersion: documentTemplateVersion,
  }) {
    const unscopedImages = unscopeImages(documentTemplateVersion.template);
    return {
      ...omit(documentTemplateVersion, [
        'id',
        'updatedAt',
        'createdAt',
        'publishedAt',
        'documentTemplateId',
        'tags',
        'bindings',
        'version',
        'changedByIAMUserId',
      ]),
      template: Base64.encode(unscopedImages),
    };
  },

  documentTemplateVersionUpdatePayload({
    editedDocumentTemplateVersion: documentTemplateVersion,
    loadedDocumentTemplateVersion: loadedDocumentTemplateVersion,
  }) {
    let updatedTemplate = unscopeImages(documentTemplateVersion.template);

    const hasLanguageChanged =
      loadedDocumentTemplateVersion.languageIdentifier !==
      documentTemplateVersion.languageIdentifier;

    if (hasLanguageChanged) {
      const { languageIdentifier: currentLanguage } = documentTemplateVersion;

      updatedTemplate = updateBindingLocale(updatedTemplate, currentLanguage);
    }

    return {
      ...omit(documentTemplateVersion, [
        'id',
        'updatedAt',
        'createdAt',
        'publishedAt',
        'documentTemplateId',
        'tags',
        'bindings',
        'version',
        'changedByIAMUserId',
      ]),
      template: Base64.encode(updatedTemplate),
    };
  },

  documentTemplateVersionBindings({
    loadedDocumentTemplateVersion: documentTemplateVersion,
    bindings: allBindings,
  }) {
    if (!allBindings) {
      return;
    }

    return documentTemplateVersion.bindings.map(
      (bindingName) => allBindings[bindingName]
    );
  },
  tagDiff({
    loadedDocumentTemplateVersion: { tags: loadedTags },
    editedDocumentTemplateVersion: { tags: editedTags },
  }) {
    const loadedTagIds = loadedTags.map((tag) => tag.id);
    const editedTagIds = editedTags
      .filter((tag) => tag.id !== undefined)
      .map((tag) => tag.id);

    // tags to be created, shape { name: 'TAGNAME' }
    const toBeCreated = (tag) => tag.id === undefined;
    const created = editedTags.filter(toBeCreated);

    // existing but newly attached: edited - loaded ids
    const attachedIds = without(editedTagIds, ...loadedTagIds) || [];
    const attached = editedTags.filter((tag) => attachedIds.includes(tag.id));
    // deleted existing tags: loaded - edited ids
    const detachedIds = without(loadedTagIds, ...editedTagIds);
    const detached = loadedTags.filter((tag) => detachedIds.includes(tag.id));

    // remaining tags - existing tags without the detached
    const untouched = editedTags.filter(
      (tag) =>
        !toBeCreated(tag) &&
        !attachedIds.includes(tag.id) &&
        !detachedIds.includes(tag.id)
    );

    return {
      old: loadedTags,
      new: editedTags,
      created,
      attached,
      detached,
      untouched,
    };
  },
};
