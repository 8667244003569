import { Form, Field } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import de from '@vee-validate/i18n/dist/locale/de.json';
import { h } from 'vue';
import { configure } from 'vee-validate';

configure({
  generateMessage: localize({
    de,
  }),
});

setLocale('de');

export const useVeeValidate = (app) => {
  // legacy names and default renderless components for a seamless upgrade
  app.component('ValidationForm', h(Form, { as: '' }));
  app.component('ValidationProvider', h(Field, { as: '' }));
};
