import { ReportBuilderApi } from '@/services/report-builder-api';

export default {
  async loadAllBindings({ commit }) {
    const { data: variableBindings } =
      await ReportBuilderApi.getVariableBindings();
    const { data: queryBindings } = await ReportBuilderApi.getQueryBindings();

    commit('setAllBindings', [...variableBindings, ...queryBindings]);
  },
};
