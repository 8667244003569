<template>
  <div class="flex flex-col gap-2 xl:grid xl:grid-cols-12">
    <div class="xl:col-span-6">
      <n-select
        id="document-template-version-tags"
        v-model="tagNames"
        :options="options"
        track-by="name"
        option-label="name"
        multiple
        label="Tags"
        :disabled="disabled"
      />
    </div>
    <div class="xl:col-span-6">
      <div class="flex gap-2">
        <n-input
          v-model="newTag"
          class="grow"
          label="Neuen Tag hinzufügen"
          :disabled="disabled"
          @keyup.enter="addNewTag"
        />
        <n-button :disabled="disabled" @click="addNewTag">
          <n-icon icon="plus" />
        </n-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DocumentTemplateVersionTags',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  data: function () {
    return {
      newTag: '',
    };
  },
  computed: {
    tagNames: {
      get() {
        return this.modelValue.map((tag) => tag.name);
      },
      set(tagNames) {
        const result = [];

        for (const tagName of tagNames) {
          const tag = this.options.find((tag) => tag.name === tagName);

          if (tag) {
            result.push(tag);
          } else {
            result.push({ name: tagName });
          }
        }

        this.$emit('update:model-value', result);
      },
    },
  },
  methods: {
    addNewTag() {
      if ('' === this.newTag) {
        return;
      }

      const lowercaseTagName = this.newTag.toLowerCase();

      const tag = this.options.find(
        (tag) => tag.name.toLowerCase() === lowercaseTagName
      );

      if (!tag) {
        // eslint-disable-next-line vue/no-mutating-props
        this.options.push({ name: this.newTag });
      }

      if (
        !this.tagNames
          .map((tagName) => tagName.toLowerCase())
          .includes(lowercaseTagName)
      ) {
        this.tagNames = [...this.tagNames, this.newTag];
      }

      this.newTag = '';
    },
  },
};
</script>
<style></style>
