import { authMiddleware } from '@epos/core-applets';
import { IamApi } from '@/services/iam-api';

export default {
  async initialize({ dispatch }) {
    dispatch('setCurrentUser');
    dispatch('documentBuilder/loadAllFooterVersions', null, { root: true });
    dispatch('documentBuilder/loadAllTags', null, { root: true });
    dispatch('documentBuilder/loadAllBindings', null, { root: true });
    dispatch('documentBuilder/loadAllCareDocuments', null, { root: true });
  },

  setCurrentUser({ commit }) {
    commit('setCurrentUser', authMiddleware.user);
  },
  async getUserInfo({ commit }, uuid) {
    try {
      if ('id' !== uuid) {
        const { givenName: firstName, familyName: lastName } =
          await IamApi.getUserById(uuid);

        commit('setUserInfo', { firstName, lastName });
      }
    } catch {
      commit('setUserInfo', null);
    }
  },

  async resetUserInfo({ commit }) {
    commit('setUserInfo', null);
  },
};
