import { hasPermissionFor } from '@/utils/permissionUtil';

const beforeEnterPermissionGuard = (resourcePath, actions, next) => {
  if (!hasPermissionFor(resourcePath, actions)) {
    next({ name: 'Forbidden403' });
  } else {
    next();
  }
};

export { beforeEnterPermissionGuard };
