export const filterBindings = (bindings, searchQuery) => {
  if (!searchQuery) {
    return bindings;
  }

  if (!bindings || !Array.isArray(bindings) || bindings.length === 0) {
    return [];
  }

  const correctBindings = new Set();

  bindings.forEach((binding) => {
    const { name, properties } = binding;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    const hitInBindingName = name.toLowerCase().includes(lowerCaseSearchQuery);

    const hitInBindingDescription =
      binding.description &&
      binding.description.toLowerCase().includes(lowerCaseSearchQuery);

    const matchingProperties = properties.filter(
      (property) =>
        property.name.toLowerCase().includes(lowerCaseSearchQuery) ||
        property.example?.toLowerCase().includes(lowerCaseSearchQuery) ||
        property.description?.toLowerCase().includes(lowerCaseSearchQuery)
    );

    if (matchingProperties.length > 0) {
      correctBindings.add({
        ...binding,
        properties: matchingProperties,
      });

      return;
    }

    if (hitInBindingName || hitInBindingDescription) {
      correctBindings.add(binding);
    }
  });

  return Array.from(correctBindings);
};
