import { mainRoutes } from '@/routes/main';
import { searchRoutes } from '@/routes/search';
import { newRoutes, editRoutes } from '@/routes/document';
import { errorRoutes } from '@/routes/errors';

export const routes = [
  ...mainRoutes,
  ...searchRoutes,
  ...newRoutes,
  ...editRoutes,
  ...errorRoutes,
];
