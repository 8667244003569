<template>
  <n-snackbar
    :model-value="!!message"
    :timer-length="timerLength"
    :variant="error ? 'error' : 'success'"
  >
    <template #message>
      {{ message }}
    </template>
  </n-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MessageSnackbar',
  props: {
    timerLength: {
      type: Number,
      default: 2000,
    },
  },
  computed: {
    ...mapState('snackbar', ['message', 'error']),
  },
  watch: {
    message: {
      immediate: true,
      handler(message) {
        if (message !== '') {
          setTimeout(
            () => this.updateSnackbar({ message: '', error: false }),
            this.timerLength
          );
        }
      },
    },
  },
  methods: {
    ...mapActions('snackbar', ['updateSnackbar']),
  },
};
</script>
