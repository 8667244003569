<template>
  <form @submit.prevent>
    <div class="px-2">
      <n-input
        id="list-search-bar"
        v-model="val"
        :label="searchLabel"
        @keydown.enter.prevent
        @update:model-value="onSearch"
      />
    </div>
  </form>
</template>

<script>
import { BINDING_TEXTS } from '@/common/constants/texts';

export default {
  name: 'BindingSearchBar',
  emits: ['search'],
  data() {
    return {
      searchLabel: BINDING_TEXTS.searchLabel,
      debounceTimer: null,
      val: '',
    };
  },
  methods: {
    onSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.emitSearch();
      }, 500);
    },
    emitSearch() {
      this.$emit('search', this.val);
    },
  },
};
</script>
