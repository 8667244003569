<template>
  <span class="text-caption text-gray-700">
    Maximal {{ maxLetters }} Zeichen.
    {{ maxLetters - currentLetterCount }} übrig
  </span>
</template>
<script>
export default {
  name: 'LetterCounter',
  props: {
    maxLetters: {
      type: Number,
      default: 160,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentLetterCount: function () {
      return this.modelValue.split('').length;
    },
  },
};
</script>
