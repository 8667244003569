import { SERVICE_ROUTE_PREFIX } from '@/constants';
import NewDocument from '@/views/NewDocument.vue';
import EditDocument from '@/views/EditDocument.vue';
import DocumentEditorForm from '@/views/DocumentEditorForm.vue';
import DocumentSettingsForm from '@/views/DocumentSettingsForm.vue';
import { beforeEnterPermissionGuard } from '@/utils/router-guards';
import { BREADCRUMBS } from '@/routes/breadcrumbs';

const newMeta = {
  title: 'Neues Dokument - Dokumentenverwaltung',
  headline: 'Dokument',
  subheadline: 'Neues Dokument',
  breadcrumbLinks: [BREADCRUMBS.documentCreate],
  links: [
    {
      label: 'Editor',
      to: { name: 'new-document-editor' },
    },
    {
      label: 'Einstellungen',
      to: { name: 'new-document-settings' },
    },
  ],
};

export const newRoutes = [
  {
    path: `${SERVICE_ROUTE_PREFIX}/new`,
    name: 'new-document',
    redirect: { name: 'new-document-settings' },
    component: NewDocument,
    meta: newMeta,
    children: [
      {
        path: 'settings',
        name: 'new-document-settings',
        meta: newMeta,
        component: DocumentSettingsForm,
      },
      {
        path: 'editor',
        name: 'new-document-editor',
        meta: newMeta,
        component: DocumentEditorForm,
      },
    ],
    beforeEnter: (_to, _from, next) => {
      beforeEnterPermissionGuard('document-builder', ['c'], next);
    },
  },
];

const editMeta = {
  title: 'Dokument bearbeiten - Dokumentenverwaltung',
  headline: 'Dokument',
  subheadline: 'Dokument bearbeiten',
  breadcrumbLinks: [BREADCRUMBS.documentDetails],
  links: [
    {
      label: 'Editor',
      to: { name: 'edit-document-editor' },
    },
    {
      label: 'Einstellungen',
      to: { name: 'edit-document-settings' },
    },
  ],
};

export const editRoutes = [
  {
    path: `${SERVICE_ROUTE_PREFIX}/:documentTemplateVersionId/edit`,
    name: 'edit-document',
    redirect: { name: 'edit-document-editor' },
    component: EditDocument,
    meta: editMeta,
    children: [
      {
        path: 'settings',
        name: 'edit-document-settings',
        meta: editMeta,
        component: DocumentSettingsForm,
      },
      {
        path: 'editor',
        name: 'edit-document-editor',
        meta: editMeta,
        component: DocumentEditorForm,
      },
    ],
  },
];
