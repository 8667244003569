export default {
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setSearchResult(state, payload) {
    state.searchResult = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setFilter(state, payload) {
    state.filter = payload;
  },
};
