const bindingPropertiesMatch = (modifier, binding) => {
  return (
    modifier.bindingType === binding.bindingType ||
    modifier.bindingName === binding.bindingName ||
    modifier.propertyName === binding.propertyName ||
    modifier.propertyType === binding.propertyType
  );
};

const getSortedModifiers = (modifiers) =>
  modifiers.sort((a, b) => {
    if (a.bindingType && !b.bindingType) {
      return -1;
    }
    if (!a.bindingType && b.bindingType) {
      return 1;
    }
    return 0;
  });

const setText = (origin, bindingName, propertyName) => {
  let text = '';

  if (origin === 'query') {
    text = `{{${bindingName}.${propertyName}}}`;
  }

  if (origin === 'variable') {
    text = `{{${bindingName}}}`;
  }

  return text;
};

const applyModifier = (binding, origin, modifier, correlatingDocumentVersion) =>
  modifier.modifierFn(binding, origin, correlatingDocumentVersion);

export const insertTextPipeline = (
  binding,
  origin,
  modifiers,
  correlatingDocumentVersion,
  onInsert = () => {}
) => {
  const { bindingName, propertyName } = binding;

  let text = setText(origin, bindingName, propertyName);

  if (!modifiers) {
    onInsert(text);

    return text;
  }

  const sortedModifiers = getSortedModifiers(modifiers);

  for (const modifier of sortedModifiers) {
    if (!bindingPropertiesMatch(modifier, binding)) {
      continue;
    }

    text = applyModifier(binding, origin, modifier, correlatingDocumentVersion);
  }

  onInsert(text);

  return text;
};
