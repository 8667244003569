import { ReportBuilderApi } from '@/services/report-builder-api';

export default {
  async search({ commit }, payload) {
    try {
      const { limit, offset, ...filter } = payload;

      commit('setIsLoading', true);
      commit('setFilter', filter);
      const { data } = await ReportBuilderApi.search(filter, limit, offset);

      commit('setSearchResult', data.data);
      commit('setPagination', data.pagination);
    } finally {
      commit('setIsLoading', false);
    }
  },
};
