<template>
  <n-applet-wrapper
    id="epos-document-builder"
    class="epos-document-builder-scope"
  >
    <router-view />
  </n-applet-wrapper>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppDocumentBuilder',
  async mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({ initialize: 'app/initialize' }),
  },
};
</script>
