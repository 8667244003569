export default {
  pushError(state, error) {
    state.errors.push(error);
  },
  shiftError(state) {
    state.errors.shift();
  },
  clearErrors(state) {
    state.errors = [];
  },
};
