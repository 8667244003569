<template>
  <n-applet-page :key="$route.path" aria-label="EPOS Document Builder">
    <template #header>
      <n-view-navigation
        :links="$route.meta.links"
        :breadcrumb-links="$route.meta.breadcrumbLinks"
      >
        <template #headline>
          <slot v-if="$slots.headline" name="headline" />
          <template v-else>
            {{ $route.meta.headline }}
          </template>
        </template>
      </n-view-navigation>
    </template>
    <template #content>
      <error-banner />
      <slot />
      <message-snackbar :timer-length="2000" />
    </template>
  </n-applet-page>
</template>

<script>
import MessageSnackbar from '@/components/common/MessageSnackbar';
import ErrorBanner from '@/components/common/ErrorBanner.vue';

export default {
  name: 'ViewWrapper',

  components: {
    ErrorBanner,
    MessageSnackbar,
  },
};
</script>
