import documentTemplate from './document-template';
import documentTemplateVersion from './document-template-version';

export default {
  ...documentTemplate,
  ...documentTemplateVersion,

  documentTemplateAndVersionModified(
    state,
    { isDocumentTemplateModified, isDocumentTemplateVersionModified }
  ) {
    return isDocumentTemplateModified || isDocumentTemplateVersionModified;
  },

  documentNameIsNotEmpty({ editedDocumentTemplate: documentTemplate }) {
    return (
      documentTemplate &&
      documentTemplate.name &&
      documentTemplate.name.length > 0
    );
  },

  isPublishable(state, { isPublished, documentTemplateAndVersionModified }) {
    return !isPublished && !documentTemplateAndVersionModified;
  },

  isModified(
    state,
    {
      isPublished,
      isDocumentTemplateModified,
      isDocumentTemplateVersionModified,
    }
  ) {
    return isPublished
      ? isDocumentTemplateModified
      : isDocumentTemplateModified || isDocumentTemplateVersionModified;
  },

  // TODO: we need some kind of general form validation, this is just a workaround
  isSaveable(state, { isModified, documentNameIsNotEmpty, isNewDocument }) {
    return isNewDocument
      ? documentNameIsNotEmpty
      : documentNameIsNotEmpty && isModified;
  },

  isPreviewable(state, { isModified }) {
    return !isModified && !state.preview.loading;
  },
};
