<template>
  <div class="animate-pulse">
    <ul class="list-none">
      <template v-for="element in elements" :key="element">
        <li>
          <div class="bg-gray-200 h-4 w-2/3 mt-3 ml-4 mb-2"></div>
          <div class="bg-gray-200 h-4 w-1/2 mt-3 ml-4 mb-2"></div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'BindingListLoader',
  props: {
    elements: {
      type: Number,
      default: 1,
    },
  },
};
</script>
