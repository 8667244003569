<template>
  <view-wrapper>
    <n-toolbar>
      <n-toolbar-button
        :to="{ path: '/services/document-builder/new' }"
        type="routerLink"
        class="epos-button--small mr-4"
        variant="tertiary"
        text="Neues Dokument"
        icon="mdi-text-box-plus-outline"
        disabled-text="Du hast nicht die erforderlichen Rechte, um Dokumente anzulegen"
        :disabled="!userCanCreateDocument"
        small
      />
    </n-toolbar>
    <n-searchable-list
      :loading="isLoading"
      :has-searched="true"
      :pagination="pagination"
      :items="searchResult"
      hide-select-all
      disable-selection
      class="mt-12"
      @submit="onSearchChanged"
      @update:pagination="updatePagination"
    >
      <template #search-fields>
        <n-input v-model="searchInput.name" label="Name" />
        <n-select
          v-model="searchInput.tags"
          :options="allTags"
          track-by="id"
          option-label="name"
          multiple
          searchable
          mapped
          label="Tags (Mehrfachauswahl möglich)"
        />
      </template>
      <template #item="{ item }">
        <router-link
          :to="{
            name: 'edit-document',
            params: { documentTemplateVersionId: item.id },
          }"
        >
          <n-card
            :id="`search-result-${item.id}`"
            hoverable
            elevated
            sheet
            rounded
          >
            <template #content>
              <search-result :result="item"></search-result>
            </template>
          </n-card>
        </router-link>
      </template>
    </n-searchable-list>
  </view-wrapper>
</template>

<script>
import ViewWrapper from '@/components/common/ViewWrapper';
import SearchResult from '@/components/document-builder/documents/SearchResult.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { hasPermissionFor } from '@/utils/permissionUtil';

export default {
  name: 'SearchView',
  components: {
    SearchResult,
    ViewWrapper,
  },
  data: () => {
    return {
      searchInput: {
        name: '',
        tags: [],
      },
    };
  },
  computed: {
    ...mapState('documentBuilder', ['allTags']),
    ...mapState('search', [
      'isLoading',
      'searchResult',
      'pagination',
      'filter',
    ]),
    ...mapGetters('search', ['getTagsFilter']),
    userCanCreateDocument() {
      return hasPermissionFor('document-builder', ['c']);
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        this.search(route.query);
      },
    },
    searchResult() {
      this.$nextTick(() => {
        const element = document.getElementById(
          `search-result-${this.searchResult[0]?.id}`
        );

        if (element) {
          const rect = element.getBoundingClientRect();

          if (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
              (window.innerWidth || document.documentElement.clientWidth)
          )
            element.scrollIntoView({
              block: 'center',
            });
        }
      });
    },
  },
  methods: {
    ...mapActions('search', ['search']),
    transformToOrFilter(query, ...filters) {
      filters.forEach((filter) => {
        const val = query[filter];
        if (Array.isArray(val) && val.length) {
          query[filter] = val.join(',');
        } else {
          delete query[filter];
        }
      });
    },
    updatePagination(pagination) {
      // eslint-disable-next-line no-unused-vars
      const { totalCount, ...newPagination } = pagination;
      // eslint-disable-next-line no-unused-vars
      const { limit, offset, ...searchQuery } = this.$route.query;

      this.$router.push({
        query: { ...searchQuery, ...newPagination },
      });
    },

    async onSearchChanged() {
      const { limit, offset } = this.$route.query;

      // remove keys with empty value
      const query = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(this.searchInput).filter(([_, v]) => !!v)
      );

      this.transformToOrFilter(query, 'tags');

      await this.$router.push({
        ...this.$route,
        query: { ...query, limit, offset },
      });
    },
  },
};
</script>
