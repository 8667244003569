import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/routes';

// Note: follow epos page title guidelines
// see https://careerpartner.atlassian.net/wiki/spaces/E/pages/3237380262/EPOS+Seitentitel+Format

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const useRouter = (app) => {
  router.beforeEach(async (to, _from, next) => {
    if (to.meta && to.meta.title) {
      document.title = `${to.meta.title} | EPOS | IU`;
    }

    next();
  });

  app.use(router);
};

export { router, useRouter };
