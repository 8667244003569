<template>
  <div class="bg-blue-100 z-90 shadow-verticalXs flex flex-col">
    <h3 class="px-4 pt-4 text-md font-bold uppercase">{{ headerCaption }}</h3>
    <binding-search-bar
      class="pt-4 w-full"
      @search="updateSearchQuery"
    ></binding-search-bar>
    <div class="mt-4">
      <binding-list
        v-if="!loading"
        :caption="queryBindingCaption"
        :bindings="filteredQueryBindings"
        :list-expanded="queryBindingsExpanded"
        :should-expand-properties="searchQuery.length > 0"
        :disabled="disabled"
        @update:list-expanded="queryBindingsExpanded = $event"
        @property-selected="onPropertySelected($event, 'query')"
      ></binding-list>
      <binding-list-loader v-else> </binding-list-loader>
      <hr class="my-4" />
      <binding-list
        v-if="!loading"
        :caption="variableBindingCaption"
        :bindings="filteredVariableBindings"
        :should-expand-properties="searchQuery.length > 0"
        :disabled="disabled"
        :list-expanded="variableBindingsExpanded"
        @update:list-expanded="variableBindingsExpanded = $event"
        @property-selected="onPropertySelected($event, 'variable')"
      ></binding-list>
      <binding-list-loader v-else> </binding-list-loader>
    </div>
  </div>
</template>
<script>
import BindingSearchBar from '@/components/editor/binding-widget/BindingSearchBar.vue';
import BindingList from '@/components/editor/binding-widget/BindingList.vue';
import { mapState } from 'vuex';
import { filterBindings } from '@/utils/filter-bindings';
import BindingListLoader from '@/components/editor/binding-widget/BindingListSkeletonLoader.vue';
import { BINDING_TEXTS } from '@/common/constants/texts';

export default {
  name: 'BindingWidget',
  components: { BindingListLoader, BindingList, BindingSearchBar },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['propertySelected'],
  data() {
    return {
      headerCaption: BINDING_TEXTS.headerCaption,
      queryBindingCaption: BINDING_TEXTS.queryBindingCaption,
      variableBindingCaption: BINDING_TEXTS.variableBindingCaption,
      searchQuery: '',
      queryBindingsExpanded: true,
      variableBindingsExpanded: false,
    };
  },
  computed: {
    ...mapState('documentBuilder', ['bindings']),
    ...mapState('documentBuilder', ['loading']),
    variableBindings() {
      if (this.bindings && Object.keys(this.bindings).length > 0) {
        const bindingsAsArr = Object.values(this.bindings);

        return bindingsAsArr.filter((binding) => {
          return binding.type === 'variable';
        });
      } else {
        return [];
      }
    },
    queryBindings() {
      if (this.bindings && Object.keys(this.bindings).length > 0) {
        const bindingsAsArr = Object.values(this.bindings);

        return bindingsAsArr.filter((binding) => {
          return binding.type === 'query';
        });
      } else {
        return [];
      }
    },
    filteredVariableBindings() {
      if (this.searchQuery.length <= 0) {
        return this.variableBindings;
      }

      return filterBindings(this.variableBindings, this.searchQuery);
    },
    filteredQueryBindings() {
      if (this.searchQuery.length <= 0) {
        return this.queryBindings;
      }

      return filterBindings(this.queryBindings, this.searchQuery);
    },
  },
  methods: {
    updateSearchQuery(query) {
      this.searchQuery = query;

      const searchTermFilled = query.length > 0;

      this.queryBindingsExpanded = searchTermFilled ? searchTermFilled : true;
      this.variableBindingsExpanded = searchTermFilled;
    },
    onPropertySelected(property, origin) {
      this.$emit('propertySelected', { property, origin });
    },
  },
};
</script>
