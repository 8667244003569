import tags from './actions/tags';
import careDocuments from './actions/care-documents';
import footerVersions from './actions/footer-versions';
import documentTemplate from './actions/document-template';
import documentTemplateVersion from './actions/document-template-version';
import { ReportBuilderApi } from '../../../services/report-builder-api';
import bindings from './actions/bindings';

export default {
  ...bindings,
  ...tags,
  ...footerVersions,
  ...documentTemplate,
  ...documentTemplateVersion,
  ...careDocuments,

  async documentTemplateAndVersionfromScratch({ commit }) {
    commit('setDocumentTemplateVersions', []);
    commit('resetDocumentTemplate');
    commit('resetDocumentTemplateVersion');
    commit('setDefaultFooter');
  },

  async loadDocumentTemplateAndVersionById({ commit, dispatch, state }, id) {
    if (!id) return;

    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await dispatch('loadDocumentTemplateVersion', id);
      await dispatch(
        'loadDocumentTemplate',
        state.loadedDocumentTemplateVersion.documentTemplateId
      );
      commit('documentTemplateFetched', true);
    } finally {
      commit('setLoading', false);
    }
  },

  async loadDocumentTemplatePreview({ commit }, { id, versionId, params }) {
    try {
      commit('setPreviewLoading', true);
      commit('setPreviewFile', { data: '', name: '' });

      const response = await ReportBuilderApi.loadPreview(
        id,
        versionId,
        params
      );

      let fileName;
      // get fileName from resp. header
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      commit('setPreviewFile', { data: response.data, name: fileName });
    } catch (e) {
      commit('setPreviewFile', { data: null, name: null });
      throw e;
    } finally {
      commit('setPreviewLoading', false);
    }
  },

  async createNewDocumentTemplateAndVersion({ commit, dispatch, state }) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);
    try {
      await dispatch('createNewDocumentTemplate');
      await dispatch(
        'createNewDocumentTemplateVersion',
        state.loadedDocumentTemplate.id
      );
      commit('documentTemplateFetched', true);
    } finally {
      commit('setLoading', false);
    }
  },

  async updateDocumentTemplateAndVersion({ commit, dispatch, state }) {
    commit('errors/clearErrors', null, { root: true });
    commit('setLoading', true);

    try {
      await dispatch('updateDocumentTemplate', state.loadedDocumentTemplate.id);
      await dispatch(
        'updateDocumentTemplateVersion',
        state.loadedDocumentTemplateVersion.id
      );
      commit('documentTemplateFetched', true);
      dispatch(
        'snackbar/updateSnackbar',
        { message: 'Dokument gespeichert' },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
};
