<template>
  <div>
    <div class="flex justify-between px-4 my-4" @click="toggleExpanded">
      <span
        class="text-md font-bold overflow-hidden whitespace-nowrap overflow-ellipsis"
      >
        {{ caption }}
      </span>
      <n-icon class="self-end" size="lg" :icon="chevronIcon" />
    </div>
    <template v-if="listExpanded">
      <ul v-show="bindings.length > 0" class="my-4 list-none">
        <li v-for="binding in bindings" :key="binding.id">
          <div
            class="opacity-100 duration-1000 w-full text-sm transition-opacity overflow-hidden whitespace-nowrap"
          >
            <binding
              :binding="binding"
              :is-expanded="shouldExpandProperties"
              :disabled="disabled"
              @property-selected="onPropertySelected"
            ></binding>
          </div>
        </li>
      </ul>
      <div
        v-show="bindings.length === 0"
        class="text-center text-gray-500 text-xs my-4"
      >
        {{ noResultsText }}
      </div>
    </template>
  </div>
</template>

<script>
import Binding from '@/components/editor/binding-widget/Binding.vue';
import { BINDING_TEXTS } from '@/common/constants/texts';

export default {
  name: 'BindingList',
  components: {
    Binding,
  },
  props: {
    bindings: {
      type: Array,
      default: () => [],
    },
    caption: {
      type: String,
      required: true,
    },
    listExpanded: {
      type: Boolean,
      default: false,
    },
    shouldExpandProperties: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['propertySelected', 'update:listExpanded'],
  data() {
    return {
      noResultsText: BINDING_TEXTS.noData,
    };
  },
  computed: {
    chevronIcon() {
      return this.listExpanded ? 'chevron-up' : 'chevron-down';
    },
  },
  methods: {
    onPropertySelected(selectedProperty) {
      this.$emit('propertySelected', selectedProperty);
    },
    toggleExpanded() {
      this.$emit('update:listExpanded', !this.listExpanded);
    },
  },
};
</script>
