import { omit, isEqual, isNil } from 'lodash';

export default {
  isNewDocument({ loadedDocumentTemplate: documentTemplate }) {
    return isNil(documentTemplate.id);
  },

  isDocumentTemplateModified({
    loadedDocumentTemplate,
    editedDocumentTemplate,
  }) {
    return !isEqual(loadedDocumentTemplate, editedDocumentTemplate);
  },

  documentTemplateCreatePayload({ editedDocumentTemplate }) {
    return omit(editedDocumentTemplate, [
      'id',
      'active',
      'updatedAt',
      'createdAt',
      'changedByIAMUserId',
    ]);
  },

  documentTemplateUpdatePayload({ editedDocumentTemplate }) {
    return omit(editedDocumentTemplate, [
      'id',
      'updatedAt',
      'createdAt',
      'changedByIAMUserId',
    ]);
  },
};
