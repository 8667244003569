<template>
  <div
    class="flex flex-row items-center px-4 py-2 cursor-pointer"
    @click="handlePropertySelected"
  >
    <n-icon size="2xl" class="text-gray-500" :icon="icon" :title="iconTitle" />
    <div class="flex-grow w-3 ml-2">
      <span
        class="text-xs block overflow-hidden whitespace-nowrap text-overflow-ellipsis"
        :class="disabled ? 'opacity-50' : 'cursor-pointer'"
        :title="tooltipContent"
      >
        {{ property.name }}
      </span>
    </div>
    <n-tooltip :content="tooltipContent" :align="'left'" placement="top">
      <n-icon
        class="ml-2 text-cta self-end"
        icon="information-outline"
        size="2xl"
        @click.stop
      />
    </n-tooltip>
  </div>
</template>

<script>
import {
  BINDING_TEXTS,
  PROPERTY_TYPE_ICON_TEXTS,
} from '@/common/constants/texts';

export default {
  name: 'BindingProperty',
  props: {
    property: {
      type: Object,
      required: true,
    },
    bindingName: {
      type: String,
      required: true,
    },
    bindingType: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['propertySelected'],
  computed: {
    iconTitle() {
      return PROPERTY_TYPE_ICON_TEXTS[this.property.type] || 'text';
    },
    icon() {
      switch (this.property.type) {
        case 'string':
        case 'multiline':
          return 'text';
        case 'number':
          return 'hashtag';
        case 'date':
          return 'calendar';
        case 'boolean':
          return 'check';
        default:
          return 'text';
      }
    },

    tooltipContent() {
      let text = '';
      if (this.property.description && this.property.description !== '') {
        text += `${BINDING_TEXTS.descriptionPrefix} ${this.property.description}\n`;
      }

      if (this.property.example && this.property.example !== '') {
        text += `${BINDING_TEXTS.examplePrefix} ${this.property.example}`;
      }

      return text || BINDING_TEXTS.noInfo;
    },
  },
  methods: {
    handlePropertySelected() {
      if (this.disabled) {
        return;
      }

      this.$emit('propertySelected', {
        bindingName: this.bindingName,
        bindingType: this.bindingType,
        propertyName: this.property.name,
        propertyType: this.property.type,
      });
    },
  },
};
</script>
