export const scopeImages = (
  htmlSource,
  scope = PARAMETER_STORE.IMAGE_SCOPE
) => {
  if (!htmlSource) {
    return '';
  }

  if (htmlSource.includes(scope)) {
    return htmlSource;
  }

  return htmlSource.replace(/<img[^>]*src="([^"]*)"/g, `<img src="${scope}$1"`);
};

export const unscopeImages = (
  htmlSource,
  scope = PARAMETER_STORE.IMAGE_SCOPE
) => {
  if (!htmlSource) {
    return '';
  }

  return htmlSource.replaceAll(scope, '');
};
