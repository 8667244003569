import { getField, updateField } from 'vuex-map-fields';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import {
  NEW_DOCUMENT_TEMPLATE,
  NEW_DOCUMENT_TEMPLATE_VERSION,
} from '@/store/modules/document-builder/constants';

export default {
  namespaced: true,
  state: {
    loading: false,
    documentFetched: false,
    allTags: [],
    allFooterVersions: [],
    allCareDocuments: [],
    bindings: null,
    allDocumentTemplateVersions: [],
    loadedDocumentTemplate: NEW_DOCUMENT_TEMPLATE,
    loadedDocumentTemplateVersion: NEW_DOCUMENT_TEMPLATE_VERSION,
    editedDocumentTemplate: NEW_DOCUMENT_TEMPLATE,
    editedDocumentTemplateVersion: NEW_DOCUMENT_TEMPLATE_VERSION,
    preview: {
      loading: false,
      file: {
        data: '',
        name: '',
      },
    },
  },
  getters: {
    getField,
    ...getters,
  },
  actions,

  mutations: {
    updateField,
    ...mutations,
  },
};
