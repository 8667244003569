import { createApiClient } from '@epos/core-applets';
import { successHandler, errorHandler } from '@/utils/responseHandlers';

export const ApiClient = createApiClient({ basePath: '/reportbuilder/v1' });
ApiClient.instance.interceptors.response.use(successHandler, errorHandler);

const pluckData = (resp) => resp.data;

const getDocumentTemplates = (params) =>
  ApiClient.instance.get('document-templates', { params });

const search = (filter, limit = 20, offset = 0) =>
  ApiClient.instance.get('versions/list', {
    params: { filter, limit, offset },
  });

const getDocumentTemplateById = (documentTemplateId) =>
  ApiClient.instance
    .get(`document-templates/${documentTemplateId}`)
    .then(pluckData);

const getDocumentTemplateVersionById = (documentTemplateVersionId) =>
  ApiClient.instance
    .get(`versions/${documentTemplateVersionId}`)
    .then(pluckData);

const getAllDocumentTemplateVersions = (documentTemplateId) =>
  ApiClient.instance
    .get(`document-templates/${documentTemplateId}/versions`)
    .then(pluckData);

const patchDocumentTemplateById = (id, documentTemplate) => {
  return ApiClient.instance
    .patch(`document-templates/${id}`, documentTemplate)
    .then(pluckData);
};

const patchDocumentTemplateVersionById = (id, documentTemplateVersion) => {
  return ApiClient.instance
    .patch(`versions/${id}/upload`, documentTemplateVersion)
    .then(pluckData);
};

const publishDocumentTemplateVersion = (id) => {
  return ApiClient.instance.post(`versions/${id}/publish`).then(pluckData);
};

const createNewDocumentTemplate = (newDocumentTemplate) => {
  return ApiClient.instance
    .post('document-templates', newDocumentTemplate)
    .then(pluckData);
};

const createNewDocumentTemplateVersion = (
  documentTemplateId,
  newDocumentTemplateVersion
) => {
  return ApiClient.instance
    .post(
      `document-templates/${documentTemplateId}/versions/upload`,
      newDocumentTemplateVersion
    )
    .then(pluckData);
};

const loadPreview = (documentTemplateId, versionId, params) => {
  return ApiClient.instance.post(
    `document-templates/${documentTemplateId}/versions/${versionId}/pdf/preview/upload`,
    { ...params },
    {
      responseType: 'blob',
    }
  );
};

const getAllTags = () => ApiClient.instance.get('tags').then(pluckData);

const getAllFooterVersions = () =>
  ApiClient.instance.get('footer-versions').then(pluckData);

const getVariableBindings = () =>
  ApiClient.instance.get('bindings/variables').then(pluckData);

const getQueryBindings = () =>
  ApiClient.instance.get('bindings/queries').then(pluckData);

const createTag = (tag) => ApiClient.instance.post('tags', tag).then(pluckData);

const createTags = (newTags) =>
  Promise.all(
    newTags.map((newTag) => ReportBuilderApi.createTag(newTag).then(pluckData))
  );

const attachTagToVersion = (tagId, documentTemplateVersionId) =>
  ApiClient.instance
    .put(`versions/${documentTemplateVersionId}/tags/${tagId}`)
    .then(pluckData);

const attachTagsToVersion = (tagsToAttach, documentTemplateVersionId) =>
  Promise.all(
    tagsToAttach.map((tagToAttach) =>
      ReportBuilderApi.attachTagToVersion(
        tagToAttach.id,
        documentTemplateVersionId
      )
    )
  );

const detachTagFromVersion = (tagId, documentTemplateVersionId) =>
  ApiClient.instance
    .delete(`versions/${documentTemplateVersionId}/tags/${tagId}`)
    .then(pluckData);

const detachTagsFromVersion = (tagsToDetach, documentTemplateVersionId) =>
  Promise.all(
    tagsToDetach.map((tagToDetach) =>
      ReportBuilderApi.detachTagFromVersion(
        tagToDetach.id,
        documentTemplateVersionId
      )
    )
  );

const getAllCareDocuments = () =>
  ApiClient.instance.get('care-documents').then(pluckData);

export const ReportBuilderApi = {
  getDocumentTemplates,
  createNewDocumentTemplate,
  getAllDocumentTemplateVersions,
  getDocumentTemplateById,
  patchDocumentTemplateById,
  loadPreview,

  search,
  createNewDocumentTemplateVersion,
  getDocumentTemplateVersionById,
  patchDocumentTemplateVersionById,
  publishDocumentTemplateVersion,

  getAllFooterVersions,

  getAllTags,
  createTag,
  createTags,
  attachTagToVersion,
  attachTagsToVersion,
  detachTagFromVersion,
  detachTagsFromVersion,

  getVariableBindings,
  getQueryBindings,

  getAllCareDocuments,
};
