<template>
  <view-wrapper>
    <template #headline>
      <span>Neues Dokument</span>
    </template>
    <n-toolbar>
      <n-toolbar-button
        icon="mdi-text-box-plus-outline"
        :disabled="!isSaveable"
        text="Erstellen"
        @click="createDocument"
      />
    </n-toolbar>
    <form autocomplete="off">
      <router-view />
    </form>
  </view-wrapper>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ViewWrapper from '@/components/common/ViewWrapper';

export default {
  name: 'NewDocument',
  components: {
    ViewWrapper,
  },

  computed: {
    ...mapState('documentBuilder', {
      documentTemplateId: (state) => state.loadedDocumentTemplate.id,
      documentTemplateVersionId: (state) =>
        state.loadedDocumentTemplateVersion.id,
    }),
    ...mapGetters('documentBuilder', ['isSaveable']),
  },
  async mounted() {
    await this.documentTemplateAndVersionfromScratch();
  },
  methods: {
    ...mapActions('snackbar', ['updateSnackbar']),

    ...mapActions('documentBuilder', [
      'documentTemplateAndVersionfromScratch',
      'createNewDocumentTemplateAndVersion',
    ]),

    async createDocument() {
      await this.createNewDocumentTemplateAndVersion();
      this.$router.push({
        name: 'edit-document-editor',
        params: { documentTemplateVersionId: this.documentTemplateVersionId },
      });
      await this.updateSnackbar({ message: 'Dokument erstellt' });
    },
  },
};
</script>
