import { createStore } from 'vuex';
import app from '@/store/modules/app';
import documentBuilder from '@/store/modules/document-builder';
import search from '@/store/modules/search';
import errors from '@/store/modules/errors';
import snackbar from '@/store/modules/snackbar';

export const store = createStore({
  modules: {
    app,
    documentBuilder,
    search,
    errors,
    snackbar,
  },
});
