import { getField, updateField } from 'vuex-map-fields';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    message: '',
    error: false,
  },
  getters: {
    getField,
  },

  actions,

  mutations: {
    updateField,
    ...mutations,
  },
};
