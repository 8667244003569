const MODIFIERS = [];

const countryProperties = ['countryOfBirth', 'country'];

const countryModifiers = countryProperties.map((item) => {
  return {
    propertyName: item,
    modifierFn: (binding, origin, correlatingDocumentVersion) => {
      const languageIdentifier =
        correlatingDocumentVersion.languageIdentifier ?? 'de';

      return `{{country country=${getBinding(
        binding,
        origin
      )} lang="${languageIdentifier}"}}`;
    },
  };
});

const dateModifier = {
  propertyType: 'date',
  modifierFn: (binding, origin, correlatingDocumentVersion) => {
    const languageIdentifier =
      correlatingDocumentVersion.languageIdentifier ?? 'de';

    return `{{date date=${getBinding(
      binding,
      origin
    )} locale="${languageIdentifier}"}}`;
  },
};

MODIFIERS.push(...countryModifiers, dateModifier);

const getBinding = (binding, origin) => {
  if (origin === 'query') {
    return `${binding.bindingName}.${binding.propertyName}`;
  } else if (origin === 'variable') {
    return `${binding.bindingName}`;
  }
};

export { MODIFIERS };
