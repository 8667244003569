<template>
  <section class="grid grid-cols-12 gap-x-6 gap-y-7 mt-8">
    <div class="xl:col-span-9 col-span-12">
      <document-editor
        ref="editorRef"
        v-model="documentTemplateContent"
        :disabled="isPublished || !userCanCreateOrEditDocument"
      ></document-editor>
    </div>
    <div class="xl:col-span-3 col-span-12">
      <binding-widget
        :disabled="isPublished || !userCanCreateOrEditDocument"
        @property-selected="insertTextIntoEditor"
      ></binding-widget>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import DocumentEditor from '@/components/editor/DocumentEditor.vue';
import BindingWidget from '@/components/editor/binding-widget/BindingWidget.vue';
import { insertTextPipeline } from '@/utils/insert-text-pipeline';
import { MODIFIERS as modifiers } from '@/common/constants/bindings-modifiers';
import { hasPermissionFor } from '@/utils/permissionUtil';

export default {
  name: 'DocumentEditorForm',
  components: {
    BindingWidget,
    DocumentEditor,
  },
  computed: {
    ...mapGetters('documentBuilder', ['isPublished']),

    ...mapFields('documentBuilder', {
      documentTemplateContent: 'editedDocumentTemplateVersion.template',
      loadedDocumentTemplateVersion: 'loadedDocumentTemplateVersion',
    }),
    userCanCreateOrEditDocument() {
      return (
        hasPermissionFor('document-builder', ['c']) ||
        hasPermissionFor('document-builder', ['u'])
      );
    },
  },
  methods: {
    insertTextIntoEditor(selectedBindingProperty) {
      const { property, origin } = selectedBindingProperty;

      const currentDocumentTemplate = this.loadedDocumentTemplateVersion;

      const text = insertTextPipeline(
        property,
        origin,
        modifiers,
        currentDocumentTemplate
      );

      const editorRef = this.$refs.editorRef;

      editorRef.$nextTick(() => {
        editorRef.appendText(text);
      });
    },
  },
};
</script>
