<template>
  <n-textarea
    v-if="value !== null"
    id="document-template-version-precondition-feedback"
    label="Feedback Vorbedingungen"
    :model-value="modelValue"
    :disabled="disabled"
    @update:model-value="onInput($event)"
  />
</template>
<script>
export default {
  name: 'DocumentTemplateVersionPreconditionFeedback',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  methods: {
    onInput(value) {
      this.$emit('update:model-value', value);
    },
  },
};
</script>
