export default {
  hasErrors: (state) => !!state.errors.length,
  totalErrors: (state) => state.errors.length,
  lastError: (state) =>
    state.errors.length ? state.errors[state.errors.length - 1] : null,
  lastErrorFormatted: (state, getters) => {
    const error = getters.lastError;
    if (error) {
      if (error?.response?.data) {
        return `${error.response.data.message}`;
      } else {
        return `${error.message}`;
      }
    }
  },
};
