<template>
  <span>
    <n-chip
      v-if="published"
      class="bg-success text-white font-bold"
      :text-classes="textClasses"
    >
      Veröffentlicht
    </n-chip>
    <n-chip
      v-else
      class="bg-status-orange text-white font-bold"
      :text-classes="textClasses"
    >
      Entwurf
    </n-chip>
  </span>
</template>
<script>
export default {
  name: 'DocumentStateChip',
  props: {
    published: {
      type: Boolean,
      default: false,
    },
    textClasses: {
      type: String,
      default: undefined,
    },
  },
};
</script>
