import { Plugin } from '@ckeditor/ckeditor5-core';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

const INSERT_IMAGE_CONFIG_KEY = 'insertImage.configuredUrl';

const INSERT_IMAGE_URL_BUTTON_LABEL = 'Bild einfügen';

const SCOPE_VIEW_ATTR = 'data-scoped';
const SCOPE_MODEL_ATTR = 'dataScoped';

export const BUILD_PROMPT_MESSAGE = (configuredUrl) =>
  `Bitte geben Sie eine URL an, welche mit ${configuredUrl} beginnt.`;

export const BUILD_URL_ERR_MESSAGE = (configuredUrl) => {
  return `Die für das Bild hinterlegte URL ist nicht zulässlig. Bitte geben Sie eine URL an, welche mit ${configuredUrl} beginnt.`;
};

export default class InsertImage extends Plugin {
  static get pluginName() {
    return 'InsertImage';
  }

  init() {
    const editor = this.bindEditorInstance();

    this.registerConversions(editor);
    this.registerImageInsert(editor);
  }

  bindEditorInstance() {
    return this.editor;
  }

  registerConversions(editor) {
    this.registerConversionSchema(editor);

    this.registerUpcast(editor);
    this.registerDowncast(editor);
  }

  registerConversionSchema(editor) {
    editor.model.schema.extend('imageBlock', {
      allowAttributes: SCOPE_MODEL_ATTR,
    });
  }

  registerUpcast(editor) {
    editor.conversion.for('upcast').attributeToAttribute({
      view: SCOPE_VIEW_ATTR,
      model: SCOPE_MODEL_ATTR,
    });
  }

  registerDowncast(editor) {
    editor.conversion.for('downcast').add((dispatcher) => {
      dispatcher.on(
        `attribute:${SCOPE_MODEL_ATTR}:imageBlock`,
        (evt, data, conversionApi) => {
          if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
          }
          const { writer, mapper } = conversionApi;
          const img = this.unwrapImage(mapper, data);
          this.setScopedAttribute(data, writer, img);
        }
      );
    });
  }

  registerImageInsert(editor) {
    editor.ui.componentFactory.add(InsertImage.pluginName, (locale) => {
      const configuredUrl = this.getConfiguredUrl(editor);
      const view = this.bootstrapInsertImageButton(locale);
      this.listenToReadonlyChanges(editor, view);

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        this.handleInsertImageButtonClick(configuredUrl, editor);
      });

      return view;
    });
  }

  handleInsertImageButtonClick(configuredUrl, editor) {
    const promptMessage = BUILD_PROMPT_MESSAGE(configuredUrl);
    const imageUrl = prompt(promptMessage);

    if (!imageUrl) {
      return;
    }

    if (!imageUrl.includes(configuredUrl)) {
      alert(BUILD_URL_ERR_MESSAGE(configuredUrl));
      return;
    }

    editor.model.change((writer) => {
      this.writeImageToModel(writer, imageUrl, editor);
    });
  }

  getConfiguredUrl(editor) {
    const configuredUrl = editor.config.get(INSERT_IMAGE_CONFIG_KEY);
    if (!configuredUrl) {
      throw new Error(
        'Missing configured URL for image insert at: ' + INSERT_IMAGE_CONFIG_KEY
      );
    }
    return configuredUrl;
  }

  writeImageToModel(writer, imageUrl, editor) {
    const imageElement = writer.createElement('imageBlock', {
      src: imageUrl,
      [SCOPE_MODEL_ATTR]: 'true',
    });

    // Insert the image in the current selection location.
    editor.model.insertContent(imageElement, editor.model.document.selection);
  }

  listenToReadonlyChanges(editor, view) {
    editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
      view.set({ isEnabled: !isReadOnly });
    });
  }

  bootstrapInsertImageButton(locale) {
    const view = new ButtonView(locale);
    view.set({
      label: INSERT_IMAGE_URL_BUTTON_LABEL,
      icon: imageIcon,
      class: 'insert-image',
      tooltip: true,
    });
    return view;
  }

  setScopedAttribute(data, viewWriter, img) {
    if (!data.attributeNewValue) {
      viewWriter.removeAttribute(SCOPE_VIEW_ATTR, img);
      return;
    }
    viewWriter.setAttribute(SCOPE_VIEW_ATTR, data.attributeNewValue, img);
  }

  unwrapImage(mapper, data) {
    const figure = mapper.toViewElement(data.item);
    return figure.getChild(0);
  }
}
