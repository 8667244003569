<template>
  <p>
    Letzte Änderung:
    <a
      v-if="!firstName && !lastName && uuid"
      class="font-bold"
      :href="`/services/iam/users/${uuid}/profile`"
    >
      {{ lastchangedByIAMUserIdText }}
    </a>
    <template v-else>
      {{ lastchangedByIAMUserIdText }}
    </template>
    {{ formattedUpdatedAt }}
  </p>
</template>

<script>
export default {
  name: 'LastChangedByIAMUserIdSection',
  props: {
    uuid: {
      type: String,
      default: null,
    },
    firstName: {
      type: String,
      default: null,
    },
    lastName: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
  },
  computed: {
    lastchangedByIAMUserIdText() {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName},`;
      }

      if (this.uuid) {
        return this.uuid;
      }

      return '';
    },
    formattedUpdatedAt() {
      return new Intl.DateTimeFormat('default', { dateStyle: 'short' }).format(
        new Date(this.updatedAt)
      );
    },
  },
};
</script>
