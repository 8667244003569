<template>
  <n-select
    id="select-language"
    key="value"
    label="Sprache"
    :options="options"
    :model-value="modelValue"
    option-label="label"
    track-by="value"
    mapped
    :disabled="disabled"
    @update:model-value="onInput($event)"
  />
</template>
<script>
import { LANGUAGE_OPTIONS } from '@/constants/languages';

export default {
  name: 'DocumentTemplateLanguageSelector',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  computed: {
    options() {
      return LANGUAGE_OPTIONS;
    },
  },
  methods: {
    onInput(value) {
      this.$emit('update:model-value', value);
    },
  },
};
</script>
<style></style>
