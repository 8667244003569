import { Base64 } from 'js-base64';
import { scopeImages } from '@/utils/scopedImageTransformer';
import {
  NEW_DOCUMENT_TEMPLATE,
  NEW_DOCUMENT_TEMPLATE_VERSION,
} from '@/store/modules/document-builder/constants';

export default {
  setLoading(state, payload) {
    state.loading = payload;
  },

  documentTemplateFetched(state, payload) {
    state.hasFetched = payload;
  },

  setDocumentTemplate(state, payload) {
    state.loadedDocumentTemplate = {
      ...state.loadedDocumentTemplate,
      ...payload,
    };
    state.editedDocumentTemplate = {
      ...state.editedDocumentTemplate,
      ...payload,
    };
  },

  setDocumentTemplateVersion(state, payload) {
    const encodedSource = Base64.decode(payload.template);
    const scopedSource = scopeImages(encodedSource);

    state.loadedDocumentTemplateVersion = {
      ...state.loadedDocumentTemplateVersion,
      ...payload,
      template: scopedSource,
    };
    state.editedDocumentTemplateVersion = {
      ...state.editedDocumentTemplateVersion,
      ...payload,
      template: scopedSource,
    };
  },

  resetDocumentTemplate(state) {
    state.loadedDocumentTemplate = NEW_DOCUMENT_TEMPLATE;
    state.editedDocumentTemplate = NEW_DOCUMENT_TEMPLATE;
  },

  resetDocumentTemplateVersion(state) {
    state.loadedDocumentTemplateVersion = NEW_DOCUMENT_TEMPLATE_VERSION;
    state.editedDocumentTemplateVersion = NEW_DOCUMENT_TEMPLATE_VERSION;
  },

  setDocumentTemplateVersions(state, payload) {
    const allDocumentVersions = [];
    for (const documentVersion of payload) {
      const encodedSource = Base64.decode(documentVersion.template);
      const scopedSource = scopeImages(encodedSource);

      const decodedDocumentVersion = {
        ...documentVersion,
        template: scopedSource,
      };

      allDocumentVersions.push(decodedDocumentVersion);
    }

    state.allDocumentTemplateVersions = allDocumentVersions;
  },

  setPreviewLoading(state, loading) {
    state.preview.loading = loading;
  },

  setPreviewFile(state, data) {
    state.preview.file = {
      data: data.data,
      name: data.name || 'preview.pdf',
    };
  },

  setAllTags(state, tags) {
    state.allTags = tags;
  },

  setAllCareDocuments(state, careDocuments) {
    state.allCareDocuments = careDocuments;
  },

  setAllBindings(state, bindings) {
    state.bindings = bindings.reduce(
      (memo, binding) => ({
        ...memo,
        [binding.name]: binding,
      }),
      {}
    );
  },

  setAllFooterVersions(state, footerVersions) {
    state.allFooterVersions = footerVersions;
    const footerVersionName =
      footerVersions.length > 0 ? footerVersions[0].name : '';

    state.loadedDocumentTemplateVersion = {
      ...state.loadedDocumentTemplateVersion,
      footerVersionName,
    };
    state.editedDocumentTemplateVersion = {
      ...state.editedDocumentTemplateVersion,
      footerVersionName,
    };
  },

  setDefaultFooter(state) {
    const footerVersionName =
      state.allFooterVersions.length > 0 ? state.allFooterVersions[0].name : '';

    state.loadedDocumentTemplateVersion = {
      ...state.loadedDocumentTemplateVersion,
      footerVersionName,
    };
    state.editedDocumentTemplateVersion = {
      ...state.editedDocumentTemplateVersion,
      footerVersionName,
    };
  },
};
