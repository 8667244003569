import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    currentUser: null,
    userInfo: {
      firstName: null,
      lastName: null,
    },
  },
  actions,
  mutations,
};
