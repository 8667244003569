/* eslint-disable vue/no-multiple-template-root */
<template>
  <n-textarea
    v-if="modelValue !== null"
    id="document-template-version-description"
    label="Beschreibung"
    :model-value="modelValue"
    :disabled="disabled"
    @update:model-value="onInput($event)"
  />
</template>
<script>
export default {
  name: 'DocumentTemplateVersionDescription',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  methods: {
    onInput(value) {
      this.$emit('update:model-value', value);
    },
  },
};
</script>
