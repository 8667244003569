<template>
  <view-wrapper>
    <div class="flex items-center justify-center h-screen">
      <n-error-page>
        <template #actions>
          <n-button variant="tertiary" @click="goBack"> Zurück </n-button>

          <n-button variant="tertiary" @click="goHome"> Home </n-button>
        </template>
      </n-error-page>
    </div>
  </view-wrapper>
</template>
<script>
import ViewWrapper from '@/components/common/ViewWrapper.vue';

export default {
  name: 'GeneralErrorPage',
  components: {
    ViewWrapper,
  },
  methods: {
    goBack() {
      window.history.back();
    },
    goHome() {
      this.$router.replace({ name: 'search' });
    },
  },
};
</script>
