<template>
  <view-wrapper>
    <template #headline>
      <span>{{ documentTemplateName }}</span>
      <document-version-chip
        class="inline-block align-text-bottom ml-2"
        :version="documentTemplateVersion"
      />
      <document-state-chip
        v-if="documentTemplateName"
        class="inline-block align-text-bottom ml-2"
        :published="isPublished"
      />
    </template>
    <n-toolbar>
      <n-toolbar-button
        icon="mdi-content-save-outline"
        text="Speichern"
        :disabled-text="saveButtonDisabledText"
        :disabled="!isSaveable || !userCanEditDocument"
        @click="save"
      />
      <n-toolbar-button
        icon="mdi-publish"
        :disabled="!isPublishable || !userCanCreateDocument"
        :disabled-text="publishButtonDisabledText"
        text="Veröffentlichen"
        @click="publishVersion"
      />
      <n-toolbar-button
        v-if="isPublished"
        icon="mdi-text-box-plus-outline"
        :disabled="isSaveable || !userCanCreateDocument"
        :disabled-text="draftButtonDisabledText"
        text="Entwurf erstellen"
        @click="createNewDraftVersion"
      />
      <n-button
        :disabled="!isPreviewable"
        variant="quaternary"
        @click="createPreview"
      >
        <n-icon
          :icon="preview.loading ? 'mdi-loading' : 'mdi-file-find'"
          class="icon-lg mr-2"
          :class="preview.loading && 'animate-spin'"
        ></n-icon>
        Vorschau
      </n-button>
      <n-button
        :disabled="!isPreviewable"
        variant="quaternary"
        @click="openPreviewModal"
      >
        <n-icon
          :icon="preview.loading ? 'mdi-loading' : 'mdi-file-cog'"
          class="icon-lg mr-2"
          :class="preview.loading ? 'animate-spin' : ''"
        ></n-icon>
        Personalisierte Vorschau
      </n-button>
    </n-toolbar>
    <form autocomplete="off">
      <router-view />
    </form>
    <preview-modal
      :is-open="isPreviewModalOpen"
      :is-loading="preview.loading"
      :bindings="documentTemplateVersionBindings"
      @close="closePreviewModal"
      @submit="createPreview"
    />
  </view-wrapper>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ViewWrapper from '@/components/common/ViewWrapper';
import DocumentStateChip from '@/components/document-builder/DocumentStateChip';
import DocumentVersionChip from '@/components/document-builder/DocumentVersionChip';
import PreviewModal from '@/components/document-builder/PreviewModal.vue';
import { hasPermissionFor } from '@/utils/permissionUtil';

export default {
  name: 'EditDocumentView',
  components: {
    ViewWrapper,
    DocumentStateChip,
    DocumentVersionChip,
    PreviewModal,
  },
  data: () => ({
    isPreviewModalOpen: false,
  }),
  computed: {
    ...mapState('documentBuilder', {
      documentTemplateId: (state) => state.loadedDocumentTemplate.id,
      documentTemplateName: (state) => state.loadedDocumentTemplate.name,

      documentTemplateVersionId: (state) =>
        state.loadedDocumentTemplateVersion.id,
      documentTemplateVersion: (state) =>
        state.loadedDocumentTemplateVersion.version,
      preview: (state) => state.preview,
    }),
    ...mapGetters('documentBuilder', [
      'documentTemplateVersionBindings',
      'isPublished',
      'isPublishable',
      'isSaveable',
      'isPreviewable',
    ]),
    userCanCreateDocument() {
      return hasPermissionFor('document-builder', ['c']);
    },
    userCanEditDocument() {
      return hasPermissionFor('document-builder', ['u']);
    },
    saveButtonDisabledText() {
      return !this.userCanEditDocument
        ? 'Du hast nicht die erforderlichen Rechte, um Dokumente zu bearbeiten'
        : '';
    },
    publishButtonDisabledText() {
      return !this.userCanCreateDocument
        ? 'Du hast nicht die erforderlichen Rechte, um Dokumente zu veröffentlichen'
        : '';
    },
    draftButtonDisabledText() {
      return !this.userCanCreateDocument
        ? 'Du hast nicht die erforderlichen Rechte, um Entwurf anzulegen'
        : '';
    },
  },
  async mounted() {
    if (!this.$route.params.documentTemplateVersionId) return;
    const { documentTemplateVersionId } = this.$route.params;
    await this.documentTemplateAndVersionfromScratch();
    await this.loadDocumentTemplateAndVersionById(documentTemplateVersionId);
    await this.loadAllDocumentTemplateVersions(this.documentTemplateId);
  },
  methods: {
    ...mapActions('snackbar', ['updateSnackbar']),
    ...mapActions('errors', ['lastError']),
    ...mapActions('documentBuilder', [
      'documentTemplateAndVersionfromScratch',
      'loadDocumentTemplateAndVersionById',
      'loadAllDocumentTemplateVersions',
      'loadDocumentTemplatePreview',
      'loadAllFooterVersions',
      'loadAllTags',
      'loadAllCareDocuments',
      'updateDocumentTemplate',
      'updateDocumentTemplateAndVersion',
      'publishDocumentTemplateVersion',
      'createNewDocumentTemplateVersion',
      'createNewDocumentTemplateVersionDraft',
    ]),

    async save() {
      if (this.isPublished) {
        await this.updateDocumentTemplate(this.documentTemplateId);
      } else {
        await this.updateDocumentTemplateAndVersion();
      }
    },

    async publishVersion() {
      await this.publishDocumentTemplateVersion(this.documentTemplateVersionId);
    },

    async createNewDraftVersion() {
      await this.createNewDocumentTemplateVersionDraft(this.documentTemplateId);
      if (this.lastError) {
        this.$router.push({
          name: 'edit-document-settings',
          params: { documentTemplateVersionId: this.documentTemplateVersionId },
        });
      }
    },

    openPreviewModal() {
      this.isPreviewModalOpen = true;
    },

    closePreviewModal() {
      this.isPreviewModalOpen = false;
    },

    async createPreview(params = { enablePrecondition: false }) {
      try {
        await this.loadDocumentTemplatePreview({
          id: this.documentTemplateId,
          versionId: this.documentTemplateVersionId,
          params,
        });
        const url = window.URL.createObjectURL(this.preview.file.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.preview.file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (e) {
        // nothing to do here
      }
    },
  },
};
</script>
<style scoped>
.n-tooltip > i {
  animation: spin 1s linear infinite;
  transform: translateY(-50%);
}
</style>
