<template>
  <div class="ml-2">
    <div
      :title="`${binding.name} - ${binding.description}`"
      class="flex justify-between px-4"
      @click="toggleExpanded"
    >
      <div class="flex flex-col flex-grow">
        <span
          class="text-md overflow-hidden whitespace-nowrap overflow-ellipsis font-bold"
        >
          {{ binding.name }}
        </span>
        <span
          class="text-xs subheading overflow-hidden whitespace-nowrap overflow-ellipsis text-gray-700"
        >
          {{ binding.description }}
        </span>
      </div>
      <n-icon class="ml-1 self-end" size="lg" :icon="chevronIcon" />
    </div>
    <ul class="my-2 ml-2">
      <template v-if="isExpandedLocal">
        <hr class="my-1 text-gray-700 px-5" />
        <li v-for="property in binding.properties" :key="property.id">
          <binding-property
            :property="property"
            :binding-name="binding.name"
            :binding-type="binding.type"
            :disabled="disabled"
            @property-selected="onPropertySelected"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import BindingProperty from '@/components/editor/binding-widget/BindingProperty.vue';

export default {
  name: 'BindingWidget',
  components: { BindingProperty },
  props: {
    binding: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['propertySelected'],
  data() {
    return {
      isExpandedLocal: this.isExpanded,
    };
  },
  computed: {
    chevronIcon() {
      return this.isExpandedLocal ? 'chevron-up' : 'chevron-down';
    },
  },
  watch: {
    isExpanded(newVal) {
      this.isExpandedLocal = newVal;
    },
  },
  created() {
    this.isExpandedLocal = this.isExpanded;
  },
  methods: {
    toggleExpanded() {
      this.isExpandedLocal = !this.isExpandedLocal;
    },
    onPropertySelected(property) {
      this.$emit('propertySelected', property);
    },
  },
};
</script>
<style>
.subheading {
  max-width: 19em;
}
</style>
