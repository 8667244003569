import { SERVICE_ROUTE_PREFIX } from '@/constants';
import SearchView from '@/views/SearchView.vue';

const breadcrumbs = {
  search: {
    label: 'Dokumentenverwaltung',
    to: {
      name: 'search',
    },
  },
};

const searchMeta = {
  title: 'Dokumentenverwaltung',
  headline: 'Suche',
  breadcrumbLinks: [breadcrumbs.search],
};

export const searchRoutes = [
  {
    path: `${SERVICE_ROUTE_PREFIX}/search`,
    name: 'search',
    component: SearchView,
    meta: searchMeta,
  },
];
