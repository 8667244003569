<template>
  <span>
    <n-chip
      v-if="version > 0"
      class="bg-blue-600 text-white font-bold"
      :text-classes="textClasses"
    >
      V{{ version }}
    </n-chip>
  </span>
</template>
<script>
export default {
  name: 'DocumentVersionChip',
  props: {
    version: {
      type: Number,
      default: 0,
    },
    textClasses: {
      type: String,
      default: undefined,
    },
  },
};
</script>
