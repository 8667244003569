export const NEW_DOCUMENT_TEMPLATE_VERSION = {
  id: null,
  version: 0,
  description: '',
  fileName: '',
  template: '',
  precondition: '',
  bindings: [],
  preconditionFeedback: '',
  publishedAt: null,
  footerVersionName: '',
  languageIdentifier: 'de',
  tags: [],
};
export const NEW_DOCUMENT_TEMPLATE = {
  id: null,
  name: '',
  careDocumentId: null,
  carePrintEnabled: false,
  active: false,
};
